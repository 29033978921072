<template>
  <div class="download">
    <div class="title">아래 편지를 꾹 눌러 다운받아주세요.</div>
    <img :src="$store.state.base64" alt="letter" class="download__letter" />
    <div class="button__wrapper">
      <div class="button" @click="complete()">다운 완료</div>
      <div class="button" @click="back()">편지 수정하기</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import html2canvas from "html2canvas";
export default {
  created() {
    if (this.$store.state.base64 == "") {
      this.$router.push("/");
    }
  },
  mounted() {
    alert("편지 이미지를 꾹 눌러 다운받아주세요.");
  },
  methods: {
    complete() {
      this.$router.push("/complete");
    },
    back() {
      this.$router.push("/write");
    },
  },
};
</script>
<style scoped>
.title {
  font-family: "GmarketSansTTF";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin: 36px 0px 12px 0px;
  word-break: keep-all;
}
.download {
  width: 100%;
  min-height: 100%;

  background-color: #f6f4e2;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-direction: column;
}
.download__letter {
  width: 100%;
  height: 100%;
}
.button__wrapper {
  width: 100%;
  padding: 0px 30px 50px 30px;
}

.button:nth-child(1) {
  width: 100%;
  height: 56px;
  border-radius: 10px;
  background-color: #ea5532;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.button:nth-child(2) {
  width: 100%;
  height: 56px;
  border-radius: 10px;
  border: 1px solid #ea5532;

  background-color: white;

  color: #ea5532;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  margin-top: 10px;
}
</style>
